import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../../components/_layout'
import { Galeria } from '../../components/Nosotros'
import { Grid } from '@material-ui/core'
import { Button } from '../../components/_common'
import theme from '../../App.theme'
import Experiencia from './Experiencia'


const ExperienciaTemplate = ( { data } ) => {
  const { experiencia } = data

  return (
      <Layout withNav seoTitle={ experiencia.titulo } seoDescription={ experiencia.resumen }>
        <Grid style={ { height: 64, width: '100%' } }/>
        <Experiencia { ...{ experiencia } } />
        <Galeria imagenes={ experiencia.galeria }/>
        <Grid container justifyContent={ 'center' } style={ { margin: theme.spacing( 2, 0 ) } }>
          <Button
              aria={ 'Regresar a todas las experiencias' }
              small
              goTo={ '/experiencias' }>
            Regresar a todos las experiencias
          </Button>
        </Grid>
      </Layout>
  )
}

export default ExperienciaTemplate

export const query = graphql`
    query($slug: String!) {
        experiencia: contentfulExperiencias(slug: { eq: $slug }) {
            slug
            titulo
            resumen
            contenido {
                raw
            }
            imagenPrincipal {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            galeria {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
    }
`