// noinspection DuplicatedCode

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { Grid } from '@material-ui/core'
import theme from '../../App.theme'
import WhatsappContact from '../../components/_common/WhatsappContact/WhatsappContact'
import { TELEPHONE } from '../../constants/navlinks'

import useStyles from './styles'
import { SectionContainer, SectionTitle } from '../../components/_layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SocialShareIcons } from '../../components/_common'


const getData = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`

const Experiencia = ( { experiencia } ) => {
  const { slug, titulo, imagenPrincipal, contenido } = experiencia
  const { site } = useStaticQuery( getData )
  const { siteUrl } = site.siteMetadata

  const contenidoBodyJSON = JSON.parse( contenido.raw )

  const styles = useStyles()

  const options = {
    renderText: ( text ) =>
        text.split( '\n' ).flatMap( ( text, i ) => [ i > 0 && <br key={ i }/>, text ] ),
    renderNode: {
      'embedded-asset-block': ( node ) => {
        return (
            <div>
              { node.data.target.fields && (
                  <img
                      width="80%"
                      src={ node.data.target.fields.file['en-US'].url }
                      alt={ titulo }
                  />
              ) }
            </div>
        )
      }
    }
  }

  return (
      <SectionContainer sectionID={ `Experiencia: ${ titulo }` }>


        <Grid container className={ styles.wrapper }>
          <Grid item container className={ styles.imgContainer }>
            <GatsbyImage
                alt={ 'imagen galeria' }
                image={ imagenPrincipal.gatsbyImageData }
                className={ styles.experienciaMainImg }/>
          </Grid>

          <SectionTitle title={ titulo }/>
          <Grid item container className={ styles.content }>
            <article>
              { documentToReactComponents( contenidoBodyJSON, options ) }
            </article>
          </Grid>
        </Grid>

        <SocialShareIcons
            text="Comparte esta experiencia Neminatura con tus amigos."
            shareTitle={ `${ titulo }` }
            url={ `${ siteUrl }/experiencias/${ slug }` }
        />

        <Grid container justifyContent={ 'center' } style={ { margin: theme.spacing( 2, 0 ) } }>
          <WhatsappContact number={ TELEPHONE }
                           message={ '¡Hola! estoy interesado en vivir una experiencia Neminatura.' }/>
        </Grid>
      </SectionContainer>
  )
}

export default Experiencia
